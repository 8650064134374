import { siteSettings } from "../../settings";
import { Button } from "../Button";

export default function WhiteMidCTANoText() {
  return (
    <div className="bg-white">
      <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          {/* <p className="text-left">
            We care very deeply about our work and the kind of impacts we can
            make with it. We have a very few sets of beliefs and strongly-held
            ideas we're betting on, about the future of enterprise and commerce
            in emerging economies.
            <br />
            <br />
            We are, therefore, building commerce-enabling financial products,
            from the ground up, stacks of new services, honest and very
            critical, to help local businesses become more successful.
            <br />
            <br />
            We serve thousands of businesses today but we are just getting
            started. They share their stories on how much Brass has done. We
            will continue to work to make entrepreneurship more permissionless
            and successful on the continent and everywhere else it is most
            needed.
          </p> */}

          {/* <br /> */}
          {/* <br /> */}
          {/* <br /> */}

          <h2 className="text-3xl font-[600] tracking-tight text-gray-900 sm:text-4xl">
            {/* Boost your productivity. */}
            {/* <br /> */}
            {/* Start using our app today. */}
            Get started trading today
          </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
            It’s easy to get started. Register an account with us and get
            started trading today.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            {/* <a
              href="#"
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Get started
            </a>
            <a
              href="#"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Learn more <span aria-hidden="true">→</span>
            </a> */}
            <Button href={`${siteSettings.appLink}/register`}>
              Get started
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
