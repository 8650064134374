const ContentOne = () => {
  const stats = [
    { label: "Deals funded since inception", value: "Over 800" },
    { label: "Amount invested in real estate", value: "$4 billion" },
    { label: "Actively earning users", value: "150,000+" },
  ];

  const values = [
    {
      name: "You should be earning like a landlord, not working like one",
      description:
        "Our investments are passive, giving you the potential to earn income without the maintenance or operational responsibilities of owning a building.",
    },
    {
      name: "The value of real estate, without buying an entire building",
      description:
        "Invest in large-scale real estate projects with other investors, without having to foot the entire bill. Leverage the power of the passive income.",
    },
    {
      name: "Take a break from the stock market. You deserve it",
      description:
        "Historically, private real estate has not been directly correlated with the stock market, which may mitigate your exposure to market volatility and may improve portfolio stability.",
    },
    // {
    //   name: "Be supportive",
    //   description:
    //     "Magnam provident veritatis odit. Vitae eligendi repellat non. Eum fugit impedit veritatis ducimus. Non qui aspernatur laudantium modi. Praesentium rerum error deserunt harum.",
    // },
    // {
    //   name: "Take responsibility",
    //   description:
    //     "Sit minus expedita quam in ullam molestiae dignissimos in harum. Tenetur dolorem iure. Non nesciunt dolorem veniam necessitatibus laboriosam voluptas perspiciatis error.",
    // },
    // {
    //   name: "Enjoy downtime",
    //   description:
    //     "Ipsa in earum deserunt aut. Quos minus aut animi et soluta. Ipsum dicta ut quia eius. Possimus reprehenderit iste aspernatur ut est velit consequatur distinctio.",
    // },
  ];

  return (
    <div>
      <div className="mx-auto -mt-12 max-w-7xl px-6 sm:mt-0 lg:px-8 xl:-mt-8">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Our mission
          </h2>
          <div className="mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row">
            <div className="lg:w-full lg:max-w-2xl lg:flex-auto">
              <p className="text-xl leading-8 text-gray-600">
                We're creating a simple marketplace for seamless investing,
                where individuals and businesses alike can connect with the
                financial opportunities they seek. Our platform is designed to
                demystify the world of investing, offering intuitive tools,
                comprehensive educational resources, and personalized support
                that cater to investors of all levels.
              </p>
              <div className="mt-10 max-w-xl text-base leading-7 text-gray-700">
                <p>
                  Whether you're taking your first step into the investment
                  world or looking to diversify your portfolio, we provide a
                  transparent, user-friendly environment that fosters confidence
                  and success. Our commitment to innovation and accessibility
                  ensures that you have everything you need to make informed
                  decisions and reach your financial goals.
                </p>
                <p className="mt-10">
                  Join us and experience a new way of investing that puts you in
                  control, simplifying the complex and making the path to
                  financial growth more attainable than ever before.
                </p>
              </div>
            </div>
            <div className="lg:flex lg:flex-auto lg:justify-center">
              <dl className="w-64 space-y-8 xl:w-80">
                {stats.map((stat) => (
                  <div
                    key={stat.label}
                    className="flex flex-col-reverse gap-y-4"
                  >
                    <dt className="text-base leading-7 text-gray-600">
                      {stat.label}
                    </dt>
                    <dd className="text-5xl font-semibold tracking-tight text-gray-900">
                      {stat.value}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>

      {/* Image section */}
      <div className="mt-32 sm:mt-40 xl:mx-auto xl:max-w-7xl xl:px-8">
        <img
          src="https://images.unsplash.com/photo-1529156069898-49953e39b3ac?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2832&q=80"
          alt=""
          className="aspect-[5/2] w-full object-cover xl:rounded-3xl"
        />
      </div>

      {/* Values section */}
      <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Real estate is already the foundation of your community. We help you
            make it the foundation of your portfolio.
          </h2>
          {/* <p className="mt-6 text-lg leading-8 text-gray-600">
            Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam
            voluptatum cupiditate veritatis in accusamus quisquam.
          </p> */}
        </div>
        <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 text-base leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {values.map((value) => (
            <div key={value.name}>
              <dt className="font-semibold text-gray-900">{value.name}</dt>
              <dd className="mt-1 text-gray-600">{value.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
};

export default ContentOne;
