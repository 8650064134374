import { siteSettings } from "../../settings";

const features = [
  {
    name: `${siteSettings.name} Account `,
    description:
      "At our platform, we offer a single, unified trading account type, designed to simplify and streamline your trading experience with us.",
  },
  {
    name: "Licensed and Regulated",
    description:
      "We hold ourselves to the highest levels of integrity and professionalism, diligently respecting and abiding by the industry standards and regulations that govern our field. ",
  },
  {
    name: "Instant account opening and funding",
    description:
      "Our registration process is designed with your convenience in mind, featuring a simple and straightforward registration form coupled with a fast an easy verification process.",
  },
  // {
  //   name: "Advanced security.",
  //   description:
  //     "Magnam provident veritatis odit. Vitae eligendi repellat non. Eum fugit impedit veritatis ducimus. Non qui aspernatur laudantium modi. Praesentium rerum error deserunt harum.",
  // },
  // {
  //   name: "Powerful API.",
  //   description:
  //     "Sit minus expedita quam in ullam molestiae dignissimos in harum. Tenetur dolorem iure. Non nesciunt dolorem veniam necessitatibus laboriosam voluptas perspiciatis error.",
  // },
  // {
  //   name: "Database backups.",
  //   description:
  //     "Ipsa in earum deserunt aut. Quos minus aut animi et soluta. Ipsum dicta ut quia eius. Possimus reprehenderit iste aspernatur ut est velit consequatur distinctio.",
  // },
];

export default function FeaturesFive() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-[1545px] px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Why we’re better
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Our team is comprised of industry experts who are driven to
            consistently improve the trading experience through experience.
          </p>
        </div>
        <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 text-base leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {features.map((feature) => (
            <div key={feature.name}>
              <dt className="font-semibold text-gray-900">{feature.name}</dt>
              <dd className="mt-1 text-gray-600">{feature.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
