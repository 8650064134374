import React from "react";
import { Header } from "../components/header/Header";
import LostOne from "../components/404/404One";
import FooterOne from "../components/footer/FooterOne";

const DefaultPage = () => {
  return (
    <div>
      <div style={{ backgroundColor: "#0c0f19" }}>
        <Header />
      </div>
      <LostOne />
      <FooterOne />
    </div>
  );
};

export default DefaultPage;
