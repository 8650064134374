import ContentTwo from "../components/content/ContentTwo";
import ContentTwoDark from "../components/content/ContentTwoDark";
import CTAFour from "../components/cta/CTAFour";
import CTAThree from "../components/cta/CTAThree";
import { CTAOne } from "../components/cta/CtaOne";
import WhiteMidCTATwo from "../components/cta/WhiteMidCTATwo";
import FooterOne from "../components/footer/FooterOne";
import { Header } from "../components/header/Header";
import TitleOne from "../components/section-title/TitleOne";

const Crypto = () => {
  return (
    <>
      <div style={{ backgroundColor: "#0c0f19" }}>
        <Header />
      </div>
      <TitleOne />
      <div style={{ backgroundColor: "#0c0f19" }}>
        <ContentTwoDark />
      </div>
      {/* <CTAThree /> */}
      <WhiteMidCTATwo />
      <FooterOne />
    </>
  );
};

export default Crypto;
