import CountUp from "react-countup";

const StatsTwo = () => {
  const stats = [
    {
      id: 1,
      name: "Paid out to traders",
      value: 100,
      suffix: "M+",
      prefix: "$",
    },
    {
      id: 2,
      name: "Countries registered with us",
      value: 180,
      suffix: "+",
      prefix: undefined,
    },
    {
      id: 3,
      name: "Volume of trades monthly",
      value: 13,
      suffix: "M+",
      prefix: undefined,
    },
    {
      id: 4,
      name: "Avg. payout processing time",
      value: 3,
      suffix: "h",
      prefix: undefined,
    },
  ];

  return (
    // className="bg-blue-600"
    <div>
      {/* Stats */}
      <div className="mx-auto  max-w-[1545px] px-6 lg:px-8 py-24 ">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
          {/* <h2 className="text-base font-semibold leading-8 text-indigo-400">
            Our track record
          </h2> */}
          <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Trusted by thousands of users&nbsp;worldwide
          </p>
          {/* <p className="mt-6 text-lg leading-8 text-[#CECFD1]">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores
            impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis
            ratione.
          </p> */}
        </div>
        <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-10 text-white sm:mt-20 sm:grid-cols-2 sm:gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-4">
          {stats.map((stat) => (
            <CountUp
              start={0}
              end={stat.value}
              duration={2.75}
              separator=" "
              decimals={0}
              decimal=","
              prefix={stat.prefix}
              suffix={stat.suffix}
              enableScrollSpy
            >
              {({ countUpRef, start }) => (
                <div
                  key={stat.id}
                  className="flex flex-col gap-y-3 border-l border-white/10 pl-6"
                >
                  <dt className="text-md leading-6">{stat.name}</dt>
                  <dd
                    className="order-first text-[36px] font-semibold tracking-tight"
                    ref={countUpRef}
                  >
                    {/* {stat.value} */}
                  </dd>
                </div>
              )}
            </CountUp>
          ))}
        </dl>
      </div>
    </div>
  );
};

export default StatsTwo;
