import { siteSettings } from "../../settings";
import { Button } from "../Button";

export default function TitleOne() {
  return (
    <div className="bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="mx-auto max-w-3xl text-center">
        {/* <p className="text-base font-semibold leading-7 text-indigo-600">
          Get the help you need
        </p> */}
        <h2 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
          Trade cryptocurrencies on our platform
        </h2>
        <p className="mt-6 text-lg leading-8 text-gray-600">
          {siteSettings.name} is excited to announce the launch of our new
          cryptocurrency trading platform. Now you can start trading Bitcoin,
          Ethereum and many more cryptocurrencies quickly, easily and safely
          from wherever you are — in just seconds. You get great margin trading
          leverage and short sell options with fast deposits and withdrawals.
          Our support team is available 24/7/365 to help get you trading on our
          CySEC-regulated platform with a trading volume of US $11 billion
          monthly.
        </p>
        <Button
          href={`${siteSettings.appLink}/register`}
          color="white"
          className="mt-10"
        >
          Get started
        </Button>
      </div>
    </div>
  );
}
