import ContentTwo from "../components/content/ContentTwo";
import ContentTwoDark from "../components/content/ContentTwoDark";
import CTAFour from "../components/cta/CTAFour";
import CTAThree from "../components/cta/CTAThree";
import { CTAOne } from "../components/cta/CtaOne";
import WhiteMidCTA from "../components/cta/WhiteMidCTA";
import WhiteMidCTANoText from "../components/cta/WhiteMidCTANoText";
import FAQThree from "../components/faq/FAQThree";
import FooterOne from "../components/footer/FooterOne";
import { Header } from "../components/header/Header";
import TitleFive from "../components/section-title/TitleFive";
import TitleFour from "../components/section-title/TitleFour";
import TitleOne from "../components/section-title/TitleOne";
import TitleThree from "../components/section-title/TitleThree";
import TitleTwo from "../components/section-title/TitleTwo";
import StatsThree from "../components/stats/StatsThree";

const faqs = [
  {
    question: "What Are Stocks?",
    answer:
      "Stocks, also commonly referred to as equities or shares, are issued by a public corporation and put up for sale. Companies originally used stocks as a way of raising additional capital, and as a way to boost their business growth. When the company first puts these stocks up for sale, this is called the Initial Public Offering. Once this stage is complete, the shares themselves are then sold on the stock market, which is where any stock trading will occur.",
  },
  {
    question: "How Do I Trade Stocks?",
    answer:
      "A stock market is where stocks are traded: where sellers and buyers come to agree on a price. Historically, stock exchanges existed in a physical location, and all transactions took place on the trading floor. One of the world’s most famous stock markets is the London Stock Exchange (LSE). Our platform provides an alternative to trading by bringing all of the tools required to trade, into one",
  },

  {
    question: "Stock Trading Risk Assessment",
    answer:
      "All forms of financial investment carry a level of risk, and stock trading is no different. Even traders with decades of experience cannot predict the correct price movements every single time. People use various strategies, but it is important to note that there is no such thing as a failsafe strategy. It is also advisable to limit the amount of money you invest in a single trade, as part of your own risk management.",
  },
];

const Stock = () => {
  return (
    <>
      <div style={{ backgroundColor: "#0c0f19" }}>
        <Header />
      </div>
      <TitleFive />
      <StatsThree />
      <br />
      <br />
      <br />
      <FAQThree faqs={faqs} />
      <WhiteMidCTANoText />
      {/* <div style={{ backgroundColor: "#0c0f19" }}>
        <ContentTwoDark />
      </div> */}

      {/* <CTAThree /> */}
      <FooterOne />
    </>
  );
};

export default Stock;
