import ContentOne from "../components/content/ContentOne";
import CTAThree from "../components/cta/CTAThree";
import CTATwo from "../components/cta/CTATwo";
import FAQThree from "../components/faq/FAQThree";
import FooterOne from "../components/footer/FooterOne";
import { Header } from "../components/header/Header";
import TitleFive from "../components/section-title/TitleFive";
import TitleFour from "../components/section-title/TitleFour";
import TitleThree from "../components/section-title/TitleThree";
import TitleTwo from "../components/section-title/TitleTwo";

const faqs = [
  {
    question: "What do I need to do to Invest?",
    answer:
      "On our platform, we have made investing in real estate as simple as creating an account and tapping a button. All you will need to do is pick a plan and raise the equity for it",
  },
  {
    question: "What is IRR?",
    answer:
      "This annualized return metric spreads cash flows and equity return over the course of the entire holding period.",
  },
  {
    question: "What is a Cash-on-Cash Return?",
    answer:
      "Sometimes also referred to as the cash yield, calculates the cash income earned on the cash invested in a property.",
  },
  {
    question: "What is the Real Estate Capital Stack?",
    answer:
      "Layers of capital that go into purchasing and outlines who will receive income and profits generated by the property.",
  },
];

const RealEstate = () => {
  return (
    <>
      <div style={{ backgroundColor: "#0c0f19" }}>
        <Header />
        <TitleThree />
      </div>
      {/* <TitleFive /> */}
      {/* <TitleFour /> */}
      {/* <TitleTwo /> */}
      <div className="py-24">
        <ContentOne />
      </div>
      <div style={{ backgroundColor: "#0c0f19" }}>
        {/* <CTATwo /> */}
        {/* <CTAThree /> */}
        <FAQThree faqs={faqs} />
      </div>
      <FooterOne />
    </>
  );
};

export default RealEstate;
