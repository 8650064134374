import {
  CheckCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid";

export default function ContentTwoDark() {
  return (
    <div className="px-6 py-32 lg:px-8">
      <div className="mx-auto max-w-3xl text-base leading-7 text-white">
        {/* <p className="text-base font-semibold leading-7 text-[#0C6CF2]">
          Introducing
        </p> */}
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
          The only platform you need to trade cryptocurrencies
        </h1>
        {/* <p className="mt-6 text-xl leading-8 ">
          Aliquet nec orci mattis amet quisque ullamcorper neque, nibh sem. At
          arcu, sit dui mi, nibh dui, diam eget aliquam. Quisque id at vitae
          feugiat egestas ac. Diam nulla orci at in viverra scelerisque eget.
          Eleifend egestas fringilla sapien.
        </p> */}
        <div className="mt-10 max-w-2xl">
          <p>
            Enjoy trading and earning from a wide range of cryptocurrencies on
            our state-of-the-art platform. With access to real-time market data,
            comprehensive analysis tools, and dedicated customer support, we
            provide everything you need to navigate the dynamic world of digital
            currencies. Whether you're a seasoned trader or just starting out,
            our user-friendly interface and educational resources make it easy
            for you to engage with the crypto market. Join us and discover the
            potential for growth and innovation in this exciting financial
            frontier.
          </p>
          {/* <ul role="list" className="mt-8 max-w-xl space-y-8 text-white">
            <li className="flex gap-x-3">
              <span>
                <strong className="font-semibold text-gray-400">
                  Flexible repayment plans
                </strong>{" "}
                Repay at your own convenience. Choose either the daily, weekly
                or monthly repayment plan.
              </span>
            </li>
            <li className="flex gap-x-3">
              <span>
                <strong className="font-semibold text-gray-400">
                  Collateral free credit
                </strong>{" "}
                Say goodbye to long requirement forms. Get the credit you need
                on time and with no collateral.
              </span>
            </li>
            <li className="flex gap-x-3">
              <span>
                <strong className="font-semibold text-gray-400">
                  Receive financing fast
                </strong>{" "}
                Once you are eligible for capital, get the cashflow you need in
                your account whenever you want.
              </span>
            </li>
          </ul> */}
          {/* <p className="mt-8">
            Et vitae blandit facilisi magna lacus commodo. Vitae sapien duis
            odio id et. Id blandit molestie auctor fermentum dignissim. Lacus
            diam tincidunt ac cursus in vel. Mauris varius vulputate et ultrices
            hac adipiscing egestas. Iaculis convallis ac tempor et ut. Ac lorem
            vel integer orci.
          </p>
          <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
            From beginner to expert in 3 hours
          </h2>
          <p className="mt-6">
            Id orci tellus laoreet id ac. Dolor, aenean leo, ac etiam consequat
            in. Convallis arcu ipsum urna nibh. Pharetra, euismod vitae interdum
            mauris enim, consequat vulputate nibh. Maecenas pellentesque id sed
            tellus mauris, ultrices mauris. Tincidunt enim cursus ridiculus mi.
            Pellentesque nam sed nullam sed diam turpis ipsum eu a sed convallis
            diam.
          </p>
          <figure className="mt-10 border-l border-indigo-600 pl-9">
            <blockquote className="font-semibold text-gray-900">
              <p>
                “Vel ultricies morbi odio facilisi ultrices accumsan donec lacus
                purus. Lectus nibh ullamcorper ac dictum justo in euismod. Risus
                aenean ut elit massa. In amet aliquet eget cras. Sem volutpat
                enim tristique.”
              </p>
            </blockquote>
            <figcaption className="mt-6 flex gap-x-4">
              <img
                className="h-6 w-6 flex-none rounded-full bg-gray-50"
                src="https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                alt=""
              />
              <div className="text-sm leading-6">
                <strong className="font-semibold text-gray-900">
                  Maria Hill
                </strong>{" "}
                – Marketing Manager
              </div>
            </figcaption>
          </figure>
          <p className="mt-10">
            Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus
            enim. Mattis mauris semper sed amet vitae sed turpis id. Id dolor
            praesent donec est. Odio penatibus risus viverra tellus varius sit
            neque erat velit.
          </p> */}
        </div>
        {/* <figure className="mt-16">
          <img
            className="aspect-video rounded-xl bg-gray-50 object-cover"
            src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&auto=format&fit=facearea&w=1310&h=873&q=80&facepad=3"
            alt=""
          />
          <figcaption className="mt-4 flex gap-x-2 text-sm leading-6 text-gray-500">
            <InformationCircleIcon
              className="mt-0.5 h-5 w-5 flex-none text-gray-300"
              aria-hidden="true"
            />
            Faucibus commodo massa rhoncus, volutpat.
          </figcaption>
        </figure> */}
        <div className="mt-16 max-w-2xl">
          <h2 className="text-2xl font-bold tracking-tight text-white">
            What is a crypto currency?
          </h2>
          <p className="mt-6">
            A cryptocurrency like bitcoin is a virtual currency traded
            peer-to-peer on a blockchain, independent of centralized authorities
            like banks and governments. Cryptocurrencies are entirely virtual,
            so they are not backed by physical commodities and have no intrinsic
            value.
          </p>
          {/* <p className="mt-8">
            Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus
            enim. Mattis mauris semper sed amet vitae sed turpis id. Id dolor
            praesent donec est. Odio penatibus risus viverra tellus varius sit
            neque erat velit.
          </p> */}
        </div>
        <div className="mt-16 max-w-2xl">
          <h2 className="text-2xl font-bold tracking-tight text-white">
            Are There Investment Opportunities With Cryptocurrencies?
          </h2>
          <p className="mt-6">
            Absolutely. Cryptocurrencies have become established investment
            commodities among major financial institutions and have even been
            adopted by countries such as Australia and Japan. As with any
            investment though, there are risks linked to market movements, high
            volatility and economics.
          </p>
          {/* <p className="mt-8">
            Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus
            enim. Mattis mauris semper sed amet vitae sed turpis id. Id dolor
            praesent donec est. Odio penatibus risus viverra tellus varius sit
            neque erat velit.
          </p> */}
        </div>
      </div>
    </div>
  );
}
