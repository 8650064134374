import ContentTwo from "../components/content/ContentTwo";
import ContentTwoDark from "../components/content/ContentTwoDark";
import CTAFour from "../components/cta/CTAFour";
import CTAThree from "../components/cta/CTAThree";
import CTATwo from "../components/cta/CTATwo";
import { CTAOne } from "../components/cta/CtaOne";
import WhiteMidCTA from "../components/cta/WhiteMidCTA";
import FooterOne from "../components/footer/FooterOne";
import { Header } from "../components/header/Header";
import TitleOne from "../components/section-title/TitleOne";
import TitleSeven from "../components/section-title/TitleSeven";

const Why = () => {
  return (
    <>
      <div style={{ backgroundColor: "#0c0f19" }}>
        <Header />
      </div>
      <TitleSeven />
      <WhiteMidCTA />
      <FooterOne />
      {/* <TitleOne /> */}
      {/* <div style={{ backgroundColor: "#0c0f19" }}>
        <ContentTwoDark />
      </div> */}
      {/* <CTAThree /> */}
    </>
  );
};

export default Why;
